<template>
	<div class="qr-container" @click="closeModal()">
		<div>
			<h2 v-if="!systemSettings.features.allowMultiCasinoAccountAccessCodes">
				{{ casinoList.filter((casino) => this.casinoId == casino.id)[0].name }}
			</h2>
			<div v-if="serverBusy" id="qr-loading">
				<span class="loading-icon"></span>
				<span class="loading-message">{{ busyText }}</span>
			</div>
			<canvas id="qr-code"></canvas>
			<p v-if="verbalCode && systemSettings.features.allowVerbalCodeAccountAccess">
				{{ languageStrings.verbalCodeIs }}: <span class="code-font">{{ rawCode }}</span>
				<br />
				{{ languageStrings.expiresIn }}
				<span :class="timer < 60 ? 'warn-text' : ''">
					{{ timer >= 60 ? Math.ceil(timer / 60) : timer }}
				</span>
				{{ timerText }}
			</p>
			<button @click="closeModal()" class="btn">{{ languageStrings.closeCode }}</button>
		</div>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";

// https://github.com/soldair/node-qrcode
import QRCode from "qrcode";

export default {
	name: "QRCodeGenerator",
	props: {
		languageStrings: Object,
		languageErrorStrings: Object,
		systemSettings: Object,
		playerState: Object,
		isMobile: Boolean,
		casinoId: Number,
		casinoList: Array,
		languageStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			serverBusy: false,
			busyText: "",
			verbalCode: null,
			width: this.isMobile ? 250 : 400,
			height: this.isMobile ? 250 : 400,
			currentTime: 0,
			verbalCodeExpire: new Date(),
			timer: 0,
			timerText: "",
			timerInterval: null,
		};
	},
	watch: {
		isMobile() {
			this.width = this.isMobile ? 250 : 400;
			this.height = this.isMobile ? 250 : 400;
			if (this.verbalCode && this.systemSettings.features.allowBarcodeAccountAccess) this.encodeQR();
		},
	},
	created() {
		this.getVerbalCode();
	},
	methods: {
		closeModal() {
			clearInterval(this.timerInterval);
			this.eventBus.emit("closeQRCodeGeneratorModal");
			this.eventBus.emit("hideShowHeaderFooter", false);
		},
		encodeQR() {
			let canvas = document.getElementById("qr-code");
			QRCode.toCanvas(canvas, this.verbalCode, { width: this.width, height: this.height }, (error = null) => {
				if (error) {
					console.error(error);
					this.status.ok = false;
					this.status.message = error;
					this.eventBus.emit("updateStatus", this.status);
				}
			});
		},
		async getVerbalCode() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.loadingCodeFromServer;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				this.closeModal();
				return false;
			}

			let body = {
				casinoId: this.casinoId || 0,
				allowCashierCashIn: true,
				allowCashierCashOut: true,
				allowCashierLockRelease: true,
			};

			let requestUrl = new URL("/api/v1/funds/code", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request).catch(() => {
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.somethingWentWrongTryLater;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					this.closeModal();
					return false;
				});

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					this.serverBusy = false;
					this.busyText = "";
					this.closeModal();
					return;
				}

				let dataJson = await response.json();

				if (fetchStatus.ok)
					this.status = sharedScripts.checkSuccessErrors(dataJson.status, this.languageErrorStrings);

				if (dataJson?.status !== "Success") {
					this.status.ok = false;
					this.status.message = this.status.message
						? this.status.message
						: this.languageErrorStrings.generateCodeUnsuccessful;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					this.closeModal();
					return false;
				}

				this.rawCode = dataJson.token.verbalCode;
				this.verbalCode = `MGSO-${dataJson.token.verbalCode}`;
				this.verbalCodeExpire = new Date(dataJson.token.expirationDate).getTime();

				if (this.verbalCode && this.systemSettings.features.allowBarcodeAccountAccess) {
					this.encodeQR();
					this.verbalCodeTimeout();
					this.eventBus.emit("hideShowHeaderFooter", true);
				}

				this.serverBusy = false;
				this.busyText = "";

				return true;
			} catch (e) {
				console.error(e);
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				this.closeModal();
				this.eventBus.emit("updateStatus", this.status);
			}
		},
		renderTimer() {
			this.timer = Math.ceil((new Date(this.verbalCodeExpire).getTime() - new Date().getTime()) / 1000);
			let minute = this.timer > 60 ? "minutes" : "minute";
			this.timerText = this.timer < 60 ? "seconds" : minute;
			if (this.timer <= 0) this.closeModal();
		},
		verbalCodeTimeout() {
			// Display timer for the user.
			this.renderTimer();
			this.timerInterval = setInterval(() => {
				this.renderTimer();
			}, 1000);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#qr-code {
	margin: auto;
	display: block;
}

#qr-loading {
	display: grid;
	justify-content: center;
}

.loading-message {
	margin: 15px;
}

.qr-container {
	display: grid;
	position: fixed;
	z-index: 500;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100vh;
	background: rgb(0 0 0 / 85%);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	color: #fff;
	align-items: center;
}

.qr-container p {
	text-align: center;
}

.qr-container .btn {
	display: block;
}

.warn-text {
	color: #f00;
	font-weight: bold;
}

.label-input {
	display: flex;
	flex-direction: column;
	width: 200px;
	align-items: center;
	margin: 15px auto;
}

.label-input * {
	margin: 5px 15px;
}

.code-font {
	font-family: monospace;
	font-size: 1.5em;
}
</style>
