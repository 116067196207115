<template>
	<div id="balances-container">
		<h3>{{ languageStrings.casinoBalance }}</h3>
		<button
			v-if="
				(systemSettings?.features?.allowBarcodeAccountAccess ||
					systemSettings?.features?.allowVerbalCodeAccountAccess) &&
				playerState.isUserVerified &&
				systemSettings?.features?.allowMultiCasinoAccountAccessCodes &&
				countryCode !== 'MX'
			"
			@click="requestOpenQRCodeModal()"
			class="btn"
		>
			{{ languageStrings.getQRcode }}
		</button>
		<table>
		<table class="balances second-table-container">
			<thead>
			<tr class="header-row">
				<!-- <th>Casino</th> -->
				<!-- <th>Regular</th>
				<th>{{ languageStrings.promo }}</th>
				<th>{{ languageStrings.lcokedFunds }}</th>
				<th>{{ languageStrings.lockFundsCount }}</th>
				<th
					v-if="
						(systemSettings?.features?.allowBarcodeAccountAccess ||
							systemSettings?.features?.allowVerbalCodeAccountAccess) &&
						playerState.isUserVerified &&
						!systemSettings?.features?.allowMultiCasinoAccountAccessCodes &&
						countryCode !== 'MX'
					"
				>
					{{ languageStrings.codeAvailable }}
				</th> -->
			</tr>
		</thead>
		<tbody v-for="(item, index) in casinosForCodeGeneration" @click="setOpenClass(index)" :key="index" :id="`row-${index}`">
			<tr class="data-row curved-border" >
				<td id="home-casino-name" class="encore-color">
					{{ item.name }}
					<i class="arrow down"></i>
				</td>
				<!-- <td class="encore-color">
					{{ item.currencyTool.formatFromAU(item.balance.regularAU, item.currencyTool.displayType.full) }}
				</td>
				<td id="home-promotions">{{ item.currencyTool.formatFromAU(item.balance.promoAU, item.currencyTool.displayType.full) }}</td>
				<td class="pink-red-color">{{ item.currencyTool.formatFromAU(item.lockedFundsAU, item.currencyTool.displayType.full) }}</td>
				<td class="encore-color">{{ item.lockedClaimsCount }}</td>
				<td
					v-if="
						(systemSettings?.features?.allowBarcodeAccountAccess ||
							systemSettings?.features?.allowVerbalCodeAccountAccess) &&
						playerState.isUserVerified &&
						!systemSettings?.features?.allowMultiCasinoAccountAccessCodes &&
						countryCode !== 'MX'
					"
				>
					<button @click="requestOpenQRCodeModal(item.id)" :title="languageStrings.getQRcode" class="btn">
						{{ languageStrings.getQRcode }}
					</button>
				</td> -->
			</tr>
			<tr class="panel">
				<td class="encore-color">
					<table class="full-width">
						<!-- <thead>
							<th>Regular</th>
							<th>{{ languageStrings.promo }}</th>
							<th>{{ languageStrings.lcokedFunds }}</th>
							<th>{{ languageStrings.lockFundsCount }}</th>
						</thead> -->
						<tr>
							<td>Regular</td>
							<td class="numerical-font-bold">{{ item.currencyTool.formatFromAU(item.balance.regularAU, item.currencyTool.displayType.full) }}</td>
							<!-- <td class="encore-text-color">
							{{ item.currencyTool.formatFromAU(item.balance.regularAU, item.currencyTool.displayType.full) }}
							</td> -->
						</tr>
						<tr>
							<td>{{ languageStrings.promo }}</td>
							<td class="numerical-font-bold" id="home-promotions">{{ item.currencyTool.formatFromAU(item.balance.promoAU, item.currencyTool.displayType.full) }}</td>
						</tr>
						<tr>
							<td>{{ languageStrings.lcokedFunds }}</td>
							<td class="pink-red-color numerical-font-bold">{{ item.currencyTool.formatFromAU(item.lockedFundsAU, item.currencyTool.displayType.full) }}</td>
						</tr>
						<tr>
							<td>{{ languageStrings.lockFundsCount }}</td>
							<td class="numerical-font-bold">{{ item.lockedClaimsCount }}</td>
						</tr>
							<!-- <td id="home-promotions">{{ item.currencyTool.formatFromAU(item.balance.promoAU, item.currencyTool.displayType.full) }}</td>
							<td class="pink-red-color">{{ item.currencyTool.formatFromAU(item.lockedFundsAU, item.currencyTool.displayType.full) }}</td>
							<td class="encore-text-color">{{ item.lockedClaimsCount }}</td> -->
						<!-- </tr> -->
					</table>
				</td>
			</tr>
			<!-- <div style="display: table;">
				<div style="display: table;"></div>

			</div> -->
		</tbody>
	</table>
	</table>
	</div>
</template>

<script>
export default {
	name: "HomeTable",
	props: {
		systemSettings: Object,
		casinosForCodeGeneration: Array,
		playerState: Object,
		languageStrings: Object,
		casinoId: Number,
	},
	mounted() {
		if(this.countryCode == 'MX') this.setOpenClass(0);
	},
	methods: {
		requestOpenQRCodeModal(casinoId = this.casinoId) {
			this.eventBus.emit("requestQRModal", casinoId);
		},

		setOpenClass(index) {
			var currentRow = document.getElementById(`row-${index}`);
			currentRow.classList.contains("open") ?  (currentRow.classList.remove("open")) :(currentRow.classList.add("open"));
			
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2,
h3 {
	text-align: center;
}

#balances-container {
	display: grid;
	margin: auto;
	width: 80%;
	background-color: white;
	border-width: 0 1px 1px 1px;
	border-style: solid;
	box-sizing: border-box;
}

.balances {
	position: relative;
	width: 100%;
	margin: auto;
	border-collapse: separate;
	text-align: center;
	background-color: white;
	/* border: 1px solid #6305C9; */
	border-spacing: 0 0.7em;
	/* box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 50%); */
}

.balances tr {
	transition: background-color 0.3s;
	height: 40px;
}

/* .balances td {
	border: 1px solid #6305C9;
	
} */

.balances thead {
	border: 1px solid black;
}

tr.header-row {
	position: sticky;
	top: 0;
	background-color: black;
	color: #fff;
	border-bottom: 1px solid black;
	white-space: break-spaces;
	height: 47px;
}

/* .balances tr.data-row:hover,
.balances tr:nth-child(2n):not(.open):hover {
	background-color: #fff;
	color: #000;
} */

/* .balances tr:nth-child(2n):not(.open) {
	background-color: white;
} */

tr td:first-of-type {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

tr td:last-of-type {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.encore-color {
	background-color: #6305C9;
	color: white;
}

.panel {
	background-color: black !important;
	color: white;
	display: none;
}

.open .panel {
	display: flex;
	flex-direction: column;
	display: table-row;
}

.numerical-font-bold {
	font-weight: bold;
}

.arrow  {
	color: #000;
	border: #000;
}

.encore-text-color {
	color: #6305C9;
}

.second-table-container {
	width: 96%;
}

.full-width {
	width: 100%;
}

@media (max-width: 767px) {
	#balances-container {
		width: 90%;
	}
}
</style>
