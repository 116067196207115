<template>
	<div 
		class="home text-color-black"
		id="router-view-hidden" 
		:class="countryCode != 'MX' ? 'gradient-background' : ''">
		<div>
			<div :id="mainNavIsHidden ? 'main-nav-hidden-home-view-header' :'home-view-header'" v-if="playerState.loggedIn">
				<p v-if="devMode" class="center-text">
					This is a placeholder, and not the final player-facing website.
				</p>
				<h1 v-if="playerState.loggedIn">{{ languageStrings.loggedInAs }} {{ playerState.displayName }}</h1>
				<h2 v-if="playerState.loggedIn">
					{{ languageStrings.phone }}
					{{
						systemSettings?.allowPhoneExtensionDigits && phoneNumberParsed?.possibility !== "is-possible"
							? playerState?.phoneNumber
							: phoneNumberParsed?.number?.national
					}}
					<small v-if="playerState.loggedIn && playerState.isUserVerified" class="verified"
						>({{ languageStrings.verified }})</small
					>
					<p v-if="playerState.permissionsSuspended" class="suspended">
						{{ languageErrorStrings.permissionsSuspended }}
					</p>
					<div v-if="playerState.loggedIn && !playerState.isUserVerified">
						<span class="not-verified">({{ languageStrings.notVerified }}.)</span>
						<br />
						<p>{{ languageStrings.gotoEditPlayer }}</p>
					</div>
				</h2>
			</div>
			<!-- <h2 v-if="casinosForCodeGeneration?.length > 0 && isMobile" class="mobile-table-header">
				{{ languageStrings.casinoBalance }}
			</h2> -->
			<div class="balances-lists-container">
				<HomeTable
					v-if="casinosForCodeGeneration?.length > 0"
					:systemSettings="systemSettings"
					:casinosForCodeGeneration="casinosForCodeGeneration"
					:playerState="playerState"
					:languageStrings="languageStrings"
				/>
				<!-- <HomeTableMobile
					v-if="casinosForCodeGeneration?.length > 0 && isMobile"
					:systemSettings="systemSettings"
					:casinosForCodeGeneration="casinosForCodeGeneration"
					:playerState="playerState"
					:languageStrings="languageStrings"
				/> -->
				<div v-if="playerState.loggedIn && !casinosForCodeGeneration?.length > 0">
					<h2>No {{ languageStrings.casinoBalance }}</h2>
				</div>
			</div>
		</div>
		<transition name="fade">
			<component
				v-bind:is="currentModal"
				:playerState="playerState"
				:systemSettings="systemSettings"
				:casinoId="casinoId"
				:casinoList="casinoList"
				:isMobile="isMobile"
				:languageStrings="languageStrings"
				:languageErrorStrings="languageErrorStrings"
			/>
		</transition>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import CurrencyTool from "@/dependencies/currencyTool";
import ChangeDisplayName from "@/components/ChangeDisplayName";
import QRCodeGenerator from "@/components/QRCodeGenerator";
import HomeTableMobile from "@/components/HomeTableMobile";
import HomeTable from "@/components/HomeTable";
import { onBeforeUnmount } from "vue";

// Tools from awesome-phonenumber. For now were just using parsePhoneNumber
// https://www.npmjs.com/package/awesome-phonenumber
import {
	parsePhoneNumber,
	// getNumberFrom,
	// getExample,
	// getCountryCodeForRegionCode,
	// getRegionCodeForCountryCode,
	// getSupportedCallingCodes,
	// getSupportedRegionCodes,
	// getAsYouType,
} from "awesome-phonenumber";

export default {
	name: "Home",
	components: {
		ChangeDisplayName,
		QRCodeGenerator,
		HomeTableMobile,
		HomeTable,
	},
	props: {
		languageStrings: Object,
		languageErrorStrings: Object,
		systemSettings: Object,
		playerState: Object,
		casinoList: Array,
		isMobile: Boolean,
		mainNavIsHidden: Boolean,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			body: document.querySelector("body"),
			currentModal: null,
			casinoId: null,
			filteredCasinoList: [],
			casinosForCodeGeneration: [],
			phoneNumberParsed: {},
			balancesListed: false,
			locationOrigin: "",
		};
	},
	watch: {
		playerState: {
			handler() {
				if (this.playerState.loggedIn) {
					// && this.playerState?.casinoBalances?.length > 0) {
					this.getCasinosForCodeGeneration();
					this.formatPhoneInternational();
					if (!this.balancesListed && this.casinosForCodeGeneration.length < 0) {
						this.getCurrentBalances(this.playerState);
						this.balancesListed = true;
					} else if (!this.playerState.loggedIn) {
						this.balancesListed = false;
					}
				}
			},
			deep: true,
		},
		casinoList() {
			if (this.playerState.loggedIn && this.playerState?.casinoBalances?.length > 0)
				this.getCasinosForCodeGeneration();
		},
	},
	async created() {
		if (this.playerState.loggedIn) {
			let acknowlegements = await this.listDocumentAcknowlegement(this, this.languageErrorStrings);
		}

		this.eventBus.on("updateBalance", (payload) => {
			this.getCurrentBalances(payload);
		});
		this.eventBus.on("closeChangeDisplayNameModal", () => {
			this.currentModal = null;
		});
		this.eventBus.on("closeQRCodeGeneratorModal", () => {
			this.currentModal = null;
		});
		this.eventBus.on("loggedOut", () => {
			this.casinosForCodeGeneration = [];
		});
		this.eventBus.on("requestQRModal", (casinoId) => {
			this.openQRCodeModal(casinoId);
		});
		onBeforeUnmount(() => {
			this.body.onkeydown = null;
			this.eventBus.off("updateBalance");
			this.eventBus.off("closeChangeDisplayNameModal");
			this.eventBus.off("closeQRCodeGeneratorModal");
			this.eventBus.off("loggedOut");
			this.eventBus.off("requestQRModal");
		});
	},
	mounted() {
		this.locationOrigin = window.location.origin;
		this.body.onkeydown = (e) => {
			if (e.key === "Escape") this.closeModal();
		};
		if (this.playerState?.loggedIn) {
			// && this.playerState?.casinoBalances?.length > 0) {
			this.getCasinosForCodeGeneration();
			this.formatPhoneInternational();
			this.getCurrentBalances(this.playerState);
			this.balancesListed = true;
		}
	},
	methods: {
		async getCurrentBalances(account) {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${account.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/funds", this.rabbitsfootHostUrl);

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				dataJson.forEach((balance) => {
					let matchedByCasinoId = this.casinoList.filter((casino) => balance.casinoId == casino.id)[0];
					// Insert an instance of the currency tool class specific to each casino's in-game currency rules.
					balance.currencyTool = new CurrencyTool(matchedByCasinoId.currencyInfo, this.localeString);
				});

				let _playerState = this.playerState;
				_playerState.casinoBalances = dataJson;

				this.eventBus.emit("updatePlayerState", _playerState);
			} catch (e) {
				console.error(e);
			}
		},
		formatPhoneInternational() {
			let phone = `+${this.playerState.phoneCountryCode}${this.playerState.phoneNumber}`;
			this.phoneNumberParsed = parsePhoneNumber(phone);
		},
		getCasinosForCodeGeneration() {
			// Gets a list of all casinos including casinos with zero balance.
			this.casinosForCodeGeneration = [];
			let emptyBalance = { promoAU: 0, regularAU: 0 };
			if (this?.playerState?.casinoBalances) {
				this.casinoList.forEach((casino) => {
					let casinoInstance = this.playerState.casinoBalances.filter(
						(balance) => balance.casinoId == casino.id
					)[0];
					casino.balance = casinoInstance?.balance || emptyBalance;
					casino.lockedClaimsCount = casinoInstance?.lockedClaimsCount || 0;
					casino.lockedFundsAU = casinoInstance?.lockedFundsAU || 0;
					this.casinosForCodeGeneration.push(casino);
				});
			}
		},
		openQRCodeModal(casinoId = this.casinoId) {
			this.casinoId = casinoId;
			this.currentModal = "QRCodeGenerator";
		},
		openDisplayNameModal() {
			this.currentModal = "ChangeDisplayName";
		},
		closeModal() {
			this.currentModal = null;
		},
	},
};
</script>

<style scoped>
.home {
	height: calc(100vh - 150px);
}

#home-view-header {
	margin: auto;
	padding: 15px;
	width: 90%;
	background-color: white;
	border: 1px #000 solid;
	box-sizing: border-box;
}

.mobile-table-header {
	width: 90%;
	margin: auto;
	padding: 15px;
	background-color: white;
	box-sizing: border-box;
	border-width: 0 1px 0 1px;
	border-style: solid;
}

h1,
h2,
h3 {
	text-align: center;
}

.open-text {
	padding: 1px 5px;
	background-color: #0f0;
	color: #000;
	font-weight: 700;
}

#balances-list-loading {
	text-align: center;
	display: grid;
	position: absolute;
	margin: auto;
	width: calc(80% - 30px);
	z-index: 200;
	background-color: rgb(0 0 0 / 80%);
	padding: 60px;
}

.verified {
	color: green;
}

.not-verified {
	color: #F74D4D;
	/* background-color: #6305C9; */
	padding: 3px 5px 4px;
}

.change-name {
	display: block;
}

.encore-background {
	height: 100vh;
	background-size: cover;
	overflow: hidden auto;
}

#main-nav-hidden-home-view-header {
	margin: auto;
	padding: 15px;
	width: 80%;
	background-color: white;
	border: 1px #000 solid;
	box-sizing: border-box;
	margin-top: 12.5vh;
}

#router-view-hidden {
	height: 100vh;
}

.text-color-black {
	color:black;
}

@media (min-width: 768px) {
	#home-view-header {
		width: 80%;
	}
}

@media (max-width: 767px) {
	#router-view {
		height: 90vh;
	}
}
</style>
