import { createStore } from "vuex";

export default createStore({
  state: {
    playerState: {},
    casinoList: [],
  },
  getters: {
    playerState(state) {
      return state.playerState;
    },
    casinoList(state) {
      return state.casinoList;
    },
  },
  mutations: {
    updatePlayerState(state, payload) {
      state.playerState = payload;
    },
    updateCasinoList(state, payload) {
      state.casinoList = payload;
    },
  },
  actions: {
    setPlayerState(state, payload) {
      this.commit('updatePlayerState', payload);
    },
    setCasinoList(state, payload) {
      this.commit('updateCasinoList', payload);
    },
  },
  modules: {},
});
