<template>
	<footer>
		<h3>{{ casinoGroupName }}</h3>
		<div class="versions">
			<span>Server Version: {{ serverVersion.serverVersion }}</span>
			<span>Operator Apps Version: {{ operatorAppsVersion }}</span>
		</div>
	</footer>
</template>

<script>
export default {
	name: "Footer",
	props: {
		serverVersion: Object,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #434250;
	text-align: center;
	/* z-index: -1; */
}

.versions {
	display: none;
	flex-flow: column nowrap;
	position: absolute;
	top: 5px;
	left: 0;
	margin: 5px 15px;
	text-align: left;
}

.versions span {
	user-select: all;
}

@media (min-width: 768px) {
	.versions {
		display: flex;
	}
}
</style>
